
import { mapGetters } from 'vuex';

export default {
  props: {
    quiz: {
      type: Object,
    },
    balance: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currentDefaultAnswer: '',
      selectedAnswer: null,
    };
  },
  computed: {
    ...mapGetters('quiz', ['getQuizAnswerList', 'getQuizVariant', 'getQuizCurrentIndex']),
    getLanguage() {
      const usedLocale = this.$i18n.locales.find((locale) => locale.code === this.$i18n.locale);
      return usedLocale ? usedLocale.acceptedLanguage.substring(0, 2) : this.$i18n.locale;
    },
  },
  mounted() {
    this.getSelectedAnswer();
  },
  methods: {
    updateAnswerHandler() {
      this.stepCompletedEvent(this.getQuizCurrentIndex + 1);

      this.$emit('get-answer', { answer: this.currentDefaultAnswer, id: this.quiz.id });
      this.currentDefaultAnswer = '';
    },
    getSelectedAnswer() {
      const { id } = this.quiz;
      this.selectedAnswer = this.getQuizAnswerList.find((el) => el.id === id)?.answer;
    },
  },
};
