
import { mapGetters } from 'vuex';

export default {
  name: 'QuizProgress',
  computed: {
    ...mapGetters('quiz', ['getQuizTotalLength', 'getQuizCurrentIndex', 'getQuizVariant']),
    percentageValue() {
      return `1%`;
    },
    showProgressBar() {
      if (
        this.isWeightLossMonthlyTour ||
        this.isNutritionWeeklyStartTour ||
        this.shouldReplicateWeightLossMonthlyTour
      ) {
        return false;
      }
      return this.$t('quizLayout')?.showProgressBar;
    },
  },
};
