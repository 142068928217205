
export default {
  components: {
    LoseWeightHeroDefault: () =>
      import('@/components/landing-pages/lose-weight/lose-weight-hero/variants/default/default'),
    LoseWeightHeroMen: () => import('@/components/landing-pages/lose-weight/lose-weight-hero/variants/men/men'),
    LoseWeightHeroPilatesNow: () =>
      import('@/components/landing-pages/lose-weight/lose-weight-hero/variants/pilates-now/pilates-now'),
    LoseWeightHeroBodyNutrition: () =>
      import('@/components/landing-pages/lose-weight/lose-weight-hero/variants/body-nutrition/body-nutrition'),
    LoseWeightHeroNutritionKeto: () =>
      import('@/components/landing-pages/lose-weight/lose-weight-hero/variants/nutrition-keto/nutrition-keto'),
    LoseWeightHeroNutritionKetoAge: () =>
      import('@/components/landing-pages/lose-weight/lose-weight-hero/variants/nutrition-keto-age/nutrition-keto-age'),
    LoseWeightHeroMeds: () => import('@/components/landing-pages/lose-weight/lose-weight-hero/variants/meds/meds'),
    LoseWeightHeroPilatesLegalDisclaimer: () =>
      import(
        '@/components/landing-pages/lose-weight/lose-weight-hero/variants/pilates-legal-disclaimer/pilates-legal-disclaimer'
      ),
    LoseWeightHeroPilates28DayChallengeDisclaimer: () =>
      import(
        '@/components/landing-pages/lose-weight/lose-weight-hero/variants/pilates-28-day-challenge-disclaimer/pilates-28-day-challenge-disclaimer'
      ),
    LoseWeightHeroPilates28DayChallengeDisclaimerSomatic: () =>
      import(
        '@/components/landing-pages/lose-weight/lose-weight-hero/variants/pilates-28-day-challenge-disclaimer-somatic/pilates-28-day-challenge-disclaimer-somatic'
      ),
    LoseWeightHeroPilates28DayChallengeSecondayMessage: () =>
      import(
        '@/components/landing-pages/lose-weight/lose-weight-hero/variants/pilates-28-day-challenge-seconday-message/pilates-28-day-challenge-seconday-message'
      ),
    Nutrition28DaySugarChallengeLoseWeight: () =>
      import(
        '@/components/landing-pages/lose-weight/lose-weight-hero/variants/nutrition-28-day-sugar-challenge/nutrition-28-day-sugar-challenge'
      ),
    Nutrition28DayWeightLossChallenge: () =>
      import(
        '@/components/landing-pages/lose-weight/lose-weight-hero/variants/nutrition-28-day-wl-challenge/nutrition-28-day-wl-challenge'
      ),
    LoseWeightHeroNutritionKetoWeekly: () =>
      import(
        '@/components/landing-pages/lose-weight/lose-weight-hero/variants/nutrition-keto-weekly/nutrition-keto-weekly'
      ),
    Yoga28DayChallengeHero: () =>
      import(
        '@/components/landing-pages/lose-weight/lose-weight-hero/variants/yoga-28-day-challenge/yoga-28-day-challenge'
      ),
    NutritionKetoWeeklyAge: () =>
      import(
        '@/components/landing-pages/lose-weight/lose-weight-hero/variants/nutrition-keto-weekly-age/nutrition-keto-weekly-age'
      ),
  },
  props: {
    titleHero: {
      type: String,
      default: null,
    },
    landingPage: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    selectedVariant() {
      const variantsMap = {
        [this.QUIZ_VARIANTS.DEFAULT]: 'LoseWeightHeroDefault',
        [this.QUIZ_VARIANTS.PILATES_NEW_CHECKOUT]: 'LoseWeightHeroPilatesLegalDisclaimer',
        [this.QUIZ_VARIANTS.PILATES_LEGAL_DISCLAIMER]: 'LoseWeightHeroPilatesLegalDisclaimer',
        [this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT]: 'LoseWeightHeroPilates28DayChallengeDisclaimer',
        [this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LANDING_PAGE_BANNER_V1]:
          'LoseWeightHeroPilates28DayChallengeDisclaimer',
        [this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LANDING_PAGE_BANNER_V2]:
          'LoseWeightHeroPilates28DayChallengeDisclaimer',
        [this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_SOMATIC_PILATES]:
          'LoseWeightHeroPilates28DayChallengeDisclaimerSomatic',
        [this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V1]: 'LoseWeightHeroPilates28DayChallengeDisclaimer',
        [this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V2]: 'LoseWeightHeroPilates28DayChallengeDisclaimer',
        [this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_SECONDARY_TEXT]:
          'LoseWeightHeroPilates28DayChallengeSecondayMessage',
        [this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V1]:
          'LoseWeightHeroPilates28DayChallengeDisclaimer',
        [this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V2]:
          'LoseWeightHeroPilates28DayChallengeDisclaimer',
        [this.QUIZ_VARIANTS.MEN]: 'LoseWeightHeroMen',
        [this.QUIZ_VARIANTS.PILATES_NOW]: 'LoseWeightHeroPilatesNow',
        [this.QUIZ_VARIANTS.BODY_NUTRITION]: 'LoseWeightHeroBodyNutrition',
        [this.QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY]: 'LoseWeightHeroBodyNutrition',
        [this.QUIZ_VARIANTS.NUTRITION_WEELKLY_START]: 'LoseWeightHeroBodyNutrition',
        [this.QUIZ_VARIANTS.BODY_NUTRITION_WEIGHT_LOSS_REASON_V1]: 'LoseWeightHeroBodyNutrition',
        [this.QUIZ_VARIANTS.BODY_NUTRITION_WEIGHT_LOSS_REASON_V2]: 'LoseWeightHeroBodyNutrition',
        [this.QUIZ_VARIANTS.BODY_NUTRITION_ADD_ANALYSIS_STEP_V1]: 'LoseWeightHeroBodyNutrition',
        [this.QUIZ_VARIANTS.BODY_NUTRITION_ADD_ANALYSIS_STEP_V2]: 'LoseWeightHeroBodyNutrition',
        [this.QUIZ_VARIANTS.NUTRITION_KETO]: 'LoseWeightHeroNutritionKeto',
        [this.QUIZ_VARIANTS.NUTRITION_KETO_WEEKLY]: 'LoseWeightHeroNutritionKetoWeekly',
        [this.QUIZ_VARIANTS.NUTRITION_KETO_AGE]: 'LoseWeightHeroNutritionKetoAge',
        [this.QUIZ_VARIANTS.HEALTH_NO_SUGAR_CHALLENGE]: 'Nutrition28DaySugarChallengeLoseWeight',
        [this.QUIZ_VARIANTS.FAST_WEIGHT_LOSS]: 'LoseWeightHeroBodyNutrition',
        [this.QUIZ_VARIANTS.MACRO_DIET]: 'LoseWeightHeroBodyNutrition',
        [this.QUIZ_VARIANTS.OPTIMAL_WEIGHT]: 'LoseWeightHeroBodyNutrition',
        [this.QUIZ_VARIANTS.WEIGHT_LOSS_OVER_40]: 'LoseWeightHeroBodyNutrition',
        [this.QUIZ_VARIANTS.WEIGHT_LOSS_WITHOUT_FASTING]: 'LoseWeightHeroBodyNutrition',
        [this.QUIZ_VARIANTS.HEALTH_FOOD_IMPACT]: 'LoseWeightHeroBodyNutrition',
        [this.QUIZ_VARIANTS.METABOLIC_RENEWAL]: 'LoseWeightHeroBodyNutrition',
        [this.QUIZ_VARIANTS.CHOLESTEROL_CONTROL]: 'LoseWeightHeroBodyNutrition',
        [this.QUIZ_VARIANTS.WEIGHT_LOSS_SECRET]: 'LoseWeightHeroBodyNutrition',
        [this.QUIZ_VARIANTS.HEALTH_WEIGHT_LOSS_28_DAY_CHALLENGE]: 'Nutrition28DayWeightLossChallenge',
        [this.QUIZ_VARIANTS.MEDS]: 'LoseWeightHeroMeds',
        [this.QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE]: 'Yoga28DayChallengeHero',
        [this.QUIZ_VARIANTS.NUTRITION_KETO_WEEKLY_AGE]: 'NutritionKetoWeeklyAge',
      };

      return variantsMap[this.getQuizVariant] || variantsMap[this.QUIZ_VARIANTS?.DEFAULT];
    },
  },
};
